import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { Perks, PerkItem, PerkImage } from ".";
import { Slogan, Anchor, ContactsContainer } from "../components/Banner";
import { Contacts } from "../components/Contacts";
import { Layout } from "../components/Layout";
import { breakpoints } from "../styledResponsive";
import {
    ImageDesktopContainer,
    Content,
    ImageMobileContainer,
    Text,
    ImageLaptopContainer,
    BannerTextContainerDesktop,
    IntroText,
    TextContainerLaptop,
} from "./vale-dos-vinhedos";

const Banner = styled.div`
    width: 100%;
    position: relative;
`;

const TextBig = styled.h5`
    padding: 40px 0;
    font-size: 24px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.alternative};
    font-weight: 100;
    text-align: center;
`;

const TerroirPerks = styled(Perks)`
    margin-top: 40px;
`;

const TerroirBannerText = styled.p`
    font-size: 18px;
    color: #fff;
    font-weight: 100;
    text-align: center;
    width: 90%;
    ${breakpoints("width", ["90%", "90%", "550px", "650px"])};
    margin: 40px; auto;
    line-height: 24px;
    letter-spacing: 2px;
`;

const TextBigBlack = styled(TextBig)`
    color: #444;
`;

const Terroir = () => (
    <Layout>
        <Banner>
            <ContactsContainer>
                <Contacts />
            </ContactsContainer>
            <ImageDesktopContainer>
                <StaticImage
                    src="../images/terroir/banner-desktop.jpg"
                    alt="Vale dos Vinhedos"
                />
            </ImageDesktopContainer>
            <ImageMobileContainer>
                <StaticImage
                    src="../images/terroir/banner-mobile.jpg"
                    alt="Vale dos Vinhedos"
                />
            </ImageMobileContainer>
            <ImageLaptopContainer>
                <StaticImage
                    src="../images/terroir/banner-laptop.jpg"
                    alt="Vale dos Vinhedos"
                />
            </ImageLaptopContainer>
            <Content>
                <Slogan>O que Signigica Terroir?</Slogan>
                <BannerTextContainerDesktop>
                    <Text>
                        No universo do vinho, terroir tem um significado muito
                        importante. Trata-se de um determinado espaço, local ou
                        região, que reúne características específicas de solo,
                        clima, geografia, altitude, entre outros, onde todos
                        estes fatores somados resultam em um lugar único. Assim
                        se forma um terroir. Não existem dois terroirs iguais.
                        Cada um reúne características próprias, que, em função
                        disso, influenciam na particularidade da uva ali
                        cultivada e consequentemente, do vinho produzido.
                    </Text>
                    <TextBig>Terroir é e sempre será algo único.</TextBig>
                </BannerTextContainerDesktop>
                <Anchor href="#main-content">
                    <StaticImage
                        src="../images/icons/arrow-down.png"
                        alt="Ver conteúdo"
                        style={{
                            maxWidth: "100%",
                            display: "block",
                        }}
                    />
                </Anchor>
            </Content>
        </Banner>
        <TextContainerLaptop>
            <IntroText>
                No universo do vinho, terroir tem um significado muito
                importante. Trata-se de um determinado espaço, local ou região,
                que reúne características específicas de solo, clima, geografia,
                altitude, entre outros, onde todos estes fatores somados
                resultam em um lugar único. Assim se forma um terroir. Não
                existem dois terroirs iguais. Cada um reúne características
                próprias, que, em função disso, influenciam na particularidade
                da uva ali cultivada e consequentemente, do vinho produzido.
            </IntroText>
            <TextBigBlack>Terroir é e sempre será, algo único.</TextBigBlack>
        </TextContainerLaptop>
        <TerroirPerks id="main-content">
            <PerkItem>
                <PerkImage>
                    <StaticImage
                        src="../images/terroir/img-1.jpg"
                        alt="Infraestrutura de segurança com condomínio
    totalmente murado e portaria 24 horas"
                    />
                </PerkImage>
            </PerkItem>
            <PerkItem>
                <PerkImage>
                    <StaticImage
                        src="../images/terroir/img-2.jpg"
                        alt="Infraestrutura de segurança com condomínio
    totalmente murado e portaria 24 horas"
                    />
                </PerkImage>
            </PerkItem>
        </TerroirPerks>
    </Layout>
);

export default Terroir;
